
import { defineComponent, computed, onMounted } from 'vue';
import {
  account,
  getAccountBalance,
  accountBalance,
  sendTransaction,
  txState,
} from '@/hooks';
import { FAUCET, WALLET_EXTENSION } from '@/configs';
import { middleEllipsis } from '@/utils';

export default defineComponent({
  setup() {
    const balance = computed(() => {
      return accountBalance.value;
    });
    if (account.value.address) {
      getAccountBalance(account.value.address);
    }

    return {
      balance,
      account,
      FAUCET,
      txState,
      WALLET_EXTENSION,
      middleEllipsis,
      sendTransaction,
    };
  },
});
