
  <div class="fluid mt-4">
    <div class="text-2xl text-right">Current network: Westend</div>
    <div class="text-2xl">
      <a class="text-blue-400" target="_blank" :href="FAUCET">Westend Faucet</a>
    </div>
    <div>
      If cannot faucet funds, please download the json file
      <a
        class="text-blue-400"
        target="_blank"
        download
        href="/assets/5Ge87uy1J6VLoG7MBW7W7jdC4ujhcwCGhJp7FRhqZBT5q9Ww.json"
      >
        download the json file
      </a>
      and import to Polkadot{.js} wallet. The password is:
      <strong>binhbungbu69</strong>
    </div>
    <div class="text-2xl mt-2">
      <a class="text-blue-400" target="_blank" :href="WALLET_EXTENSION">
        Wallet extension
      </a>
    </div>
    <div v-if="account.address">
      <div class="mt-4 text-2xl">Balance</div>
      <div class="mt-2">
        <div
          class="
            bg-white
            rounded-md
            px-4
            py-6
            flex
            items-center
            justify-between
            shadow-md
          "
        >
          <div>
            <div>
              <span class="mr-2">Wallet name:</span>
              <span>{{ account.name }}</span>
            </div>
            <div>
              <span class="mr-2">Wallet address:</span>
              <span>
                <a
                  class="text-blue-400"
                  target="_blank"
                  :href="`https://westend.subscan.io/account/${account.address}`"
                >
                  {{ middleEllipsis(account.address) }}
                </a>
              </span>
            </div>
          </div>
          <div>{{ balance }} DOT</div>
        </div>
      </div>
      <div class="mt-4 mb-2">
        <el-button :loading="txState.loading" @click="sendTransaction">
          Make an example transaction
        </el-button>
      </div>
      <div v-if="txState.tx">
        <a
          class="text-blue-400"
          target="_blank"
          :href="`https://westend.subscan.io/extrinsic/${txState.tx}`"
        >
          {{ txState.tx }}
        </a>
      </div>
      <div v-if="txState.error" class="text-red-500">
        {{ txState.error }}
      </div>
    </div>
  </div>
